import React from 'react';
import useStyles from './styles';

export default function Spinner({ size, className }: { size: 'small' | 'medium' | 'large'; className?: string }) {
  const classses = useStyles();
  let ratio;

  switch (size) {
    case 'small':
      ratio = '25px';
      break;
    case 'medium':
      ratio = '40px';
      break;
    case 'large':
      ratio = '135px';
      break;

    default:
      ratio = '25px';
  }

  return (
    <div
      className={`${classses.loadingSpinner}${className ? ` ${className}` : ''}`}
      style={{ width: ratio, height: ratio }}
    >
      <svg className={classses.loadingSpinner__circleSvg} viewBox="25 25 50 50" style={{ width: ratio, height: ratio }}>
        <circle
          className={classses.loadingSpinner__circleStroke}
          cx="50"
          cy="50"
          r="20"
          fill="none"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
      </svg>
    </div>
  );
}

Spinner.defaultProps = {
  className: undefined,
};
