import React, { useEffect } from 'react';
import type { NextPage } from 'next';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';
import styles from '../styles/Home.module.css';
import Spinner from '../components/Spinner';
import Header from '../components/Header/Header';

const MainForm = dynamic(() => import('../components/MainForm/MainForm'), {
  ssr: false,
  loading: () => {
    const { t } = useTranslation();
    return (
      <>
        <div className={styles.titles}>
          <img src="/EverIcon.svg" alt="" className={styles.titleImg} />
          <h2 className={styles.title}>{t('home.title')}</h2>
        </div>
        <div className={styles.loader}>
          <Spinner size="large" />
        </div>

        <p className={styles.subtitle}>{t('home.subtitle')}</p>
      </>
    );
  },
});

const Home: NextPage = () => {
  const router = useRouter();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (typeof router.query.lang === 'string' && i18n.hasResourceBundle(router.query.lang, 'translation')) {
      i18n.changeLanguage(router.query.lang);
      localStorage.setItem('LANG', router.query.lang);
    } else {
      const lang = localStorage.getItem('LANG');
      if (lang && i18n.hasResourceBundle(lang, 'translation')) i18n.changeLanguage(lang);
    }
  }, [router.query]);

  return (
    <div className={styles.container}>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
        <title>{t('home.info')}</title>
        <meta name="robots" content="noindex" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.ico" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.ico" />
      </Head>

      <main className={styles.main}>
        <MainForm />
      </main>
      <div id="modalContainer" />
    </div>
  );
};

export default Home;
