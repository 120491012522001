import { makeStyles } from '@material-ui/core/';

export default makeStyles((theme) => ({
  loadingSpinner: {
    position: 'relative',
    margin: '0 auto',
    width: '75px',
    height: '75px',
  },

  loadingSpinner__circleSvg: {
    animation: '$loading-spinner-rotate 1.28973s linear infinite',
    height: '100%',
    transformOrigin: 'center center',
    width: '100%',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    margin: 'auto',
  },

  loadingSpinner__circleStroke: {
    strokeDasharray: '1, 200',
    strokeDashoffset: '0',
    animation: '$loading-spinner-dash 1.4s ease-in-out infinite, $loading-spinner-color 1.28973s ease-in-out infinite',
    strokeLinecap: 'round',
    strokeWidth: '5px',
  },

  '@keyframes loading-spinner-rotate': {
    '100%': {
      transform: 'rotate(360deg)',
    },
  },

  '@keyframes loading-spinner-dash': {
    '0%': {
      strokeDasharray: '1, 200',
      strokeDashoffset: '0',
    },
    '50%': {
      strokeDasharray: '89, 200',
      strokeDashoffset: '-35px',
    },
    '100%': {
      strokeDasharray: '89, 200',
      strokeDashoffset: '-124px',
    },
  },

  '@keyframes loading-spinner-color': {
    '0%': {
      stroke: '#11A97C',
    },
    '50%': {
      stroke: '#11A97C',
    },
    '100%': {
      stroke: '#11A97C',
    },
  },
}));
